<template>
  <div class="box">
    <div class="videoBox">
      <div class="aboutAdd">AI大数据时代的基础设置服务</div>
      <el-image class="img" :src="banner"></el-image>
    </div>

    <div class="main">
      <div class="introduceBox">
        <div class="illustrateBox">
          <div class="addBox">
            <div class="titleBox"> <span class="titleSpan">瀚维</span>科技 </div>
            <div class="txtBox">
              瀚维科技作为业界领先的数据中心供应商，致力于严苛的标准、先进的技术、优质的资源和高品质的服务，为企业提供专业的数据中心规划、建设、运营服务。
            </div>
          </div>
        </div>

        <div class="illustrateImg">
          <el-image class="img" :src="img01"></el-image>
        </div>
      </div>

      <div class="showImgBox">
        <div v-for="item in showAry" :key="item.id">
          <div class="imgBox">
            <!-- <el-image :src="deviceWidth*1 > 1800 ? item.img : item.imgSmall"></el-image> -->
            <div class="itemImgBox">
              <el-image class="img" :src="item.img"></el-image>
            </div>
            <div class="itemImgName"> {{ item.name }} </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
export default {
  name: "Home",
  data() {
    return {
      deviceWidth: window.innerWidth,
      banner: require("./images/banner.png"),
      img01: require("./images/map.png"),
      showAry: [
        {
          img: require("./images/1.png"),
          imgSmall: require("./images/1_1.png"),
          name: '高可用',
          id: "1"
        },
        {
          img: require("./images/2.png"),
          imgSmall: require("./images/2_2.png"),
          name: '安全',
          id: "2"
        },
        {
          img: require("./images/3.png"),
          imgSmall: require("./images/3_3.png"),
          name: '灵活',
          id: "3"
        },
        {
          img: require("./images/4.png"),
          imgSmall: require("./images/4_4.png"),
          name: '高效',
          id: "4"
        },
        {
          img: require("./images/5.png"),
          imgSmall: require("./images/5_5.png"),
          name: '绿色',
          id: "5"
        },
        {
          img: require("./images/6.png"),
          imgSmall: require("./images/6_6.png"),
          name: '模块化',
          id: "6"
        },
        {
          img: require("./images/7.png"),
          imgSmall: require("./images/7_7.png"),
          name: '智能化',
          id: "7"
        },
      ]

    };
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      console.log('deviceWidth', this.deviceWidth)
      this.deviceWidth = window.innerWidth;
      this.$forceUpdate(); // 强制重新渲染以响应屏幕宽度变化
    }
  }
};
</script>

<style scoped lang='less'>
// /deep/ .el-carousel__button {
//   // 指⽰器按钮
//   background-color: rgba(0, 0, 0, 0.2);
// }

// /deep/ .is-active .el-carousel__button {
//   // 指⽰器激活按钮
//   background: blue;
// }

// /deep/ .el-carousel__item {
//   height: auto;
//   overflow: hidden;
// }

// /deep/ .el-carousel--horizontal {
//   overflow: hidden;
// }

.box {
  width: 100%;
  // height: 700px;

  .videoBox {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
      font-size: 64px;
      color: #7B7583;
      position: absolute;
      z-index: 10;
      letter-spacing: 20px;
    }
  }

  .main {
    .introduceBox {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .illustrateBox {
        // width: 200px;
        flex: 1;

        .addBox {
          width: 400px;
          margin-left: auto;
          margin-right: 60px;

          .titleBox {
            font-size: 42px;
            padding: 60px 0;
            margin-bottom: 10px;

            .titleSpan {
              background: linear-gradient(to right, #70C6ED, #5AFCDF);
              -webkit-background-clip: text;
              /*将设置的背景颜色限制在文字中*/
              -webkit-text-fill-color: transparent;
              /*给文字设置成透明*/
            }
          }

          .txtBox {
            font-size: 24px;
            width: 400px;
          }
        }
      }

      .illustrateImg {
        flex: 1;
      }
    }

    .showImgBox {
      margin: 120px 60px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .imgBox {

        .itemImgBox {
          width: 210px;
          height: 682px;

          .img {
            width: 100%;
            height: 100%;
          }
        }

        .itemImgName {
          text-align: center;
          padding: 20px 0;
          font-size: 18px;
        }
      }
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }


}
</style>