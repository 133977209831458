<template>
  <div id="app">
    <Headers />
    <router-view></router-view>
    <Footers />
  </div>
</template>

<script>
import Headers from "./components/Headers/Headers";
import Footers from "./components/Footers/Footers";

export default {
  name: "App",
  components: {
    Headers,
    Footers,
  },
};
</script>

<style>
/* @import './assets/font/font.css'; */
#app {
  font-family: 'SourceHanSerifCN';
  font-weight: 400;


}
</style>
