import VueRouter from 'vue-router'

import Home from '@/pages/Home'
import About from '@/pages/About'
import Datacenter from '@/pages/Datacenter'
import Service from '@/pages/Service'
import ContactUs from '@/pages/ContactUs'

// 先把VueRouter原型对象的push，先保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

// 重写 push 和 replace
// 第一个参数：告诉原来的push方法，你往哪里跳转（传递哪些参数）
// 第二个参数：成功回调
// 第三个参数：失败的回调
VueRouter.prototype.push = function (location,resolve,reject) {
    if (resolve && reject) {
        // call||apply区别
        // 相同点：都可以调用函数一次，都可以篡改函数的上下文一次
        // 不同点：call与apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => { }, () => { });
    }
}
VueRouter.prototype.replace = function (location,resolve,reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject);
    } else {
        originReplace.call(this, location, () => { }, () => { });
    }
}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/home', 
            name:'home',
            component: Home,
        },
        {
            path: '/about',
            component: About,
        },
        {
            path: '/datacenter',
            component: Datacenter,
        },
        {
            path: '/service',
            component: Service,
        },
        {
            path: '/contactUs',
            component: ContactUs,
            // meta: { showFooter:true, }
        },
        // {
        //     path: '/search/:keyword?',
        //     component: Search,
        //     meta: { showFooter: false, },
        //     name: 'search',
        //     // 路由组建能不能传递props数据？
        //     // 布尔值写法
        //     // props: true,
        //     // 对象写法：额外的给路由组件传递一些props
        //     // props:{a:1,b:2}
        //     // 函数写法：可以params参数、query参数，通过props传递给路由组件
        //     // props: ($route) => {
        //     //     return {
        //     //         keyword: $route.params.keyword,
        //     //         k:$route.query.k
        //     //     }
        //     // }
        // },
        // {
        //     path: '/register',
        //     component: Register,
        //     meta: { showFooter:false, }
        // },
        // 重定向，访问/,立即定向到首页
        {
            path: '*',
            redirect: '/home'
        }
    ]
})

export default router
