<template>
  <div class="contain" :class="{ addBgColor: bgHeight > 500 }">
    <div class="main">
      <div class="logoBox" :class="{ listAddColor: bgHeight > 500 }">
        <div>
          <div> 瀚维科技 </div>
          <div> Hanwei Tech </div>
        </div>
      </div>
      <div class="title">
        <ul class="list">
          <li :class="{ listAddColor: bgHeight > 500 }" v-for="(item, index) in titleList" :key="index">
            <!-- <h3 @click="jumppage(index)" :class="{ active: chooseIndex == index }">{{ $t(item) }}</h3> -->
            <div @click="jumppage(index)" :class="{ active: chooseIndex == index }">{{ $t(item) }}</div>
            <div v-show="(chooseIndex === index ? true : false)" class="borderBottom"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      // titleList: ['titles.home', "titles.service", "titles.datacenter",  'titles.about', 'titles.contactUs', "titles.lange"],
      titleList: ['titles.home', "titles.service", "titles.datacenter", 'titles.about'],
      chooseIndex: 0,
      logo: require("./images/logo.png"),
      bgHeight: 0,
    };
  },
  mounted() {
    let path = this.$route.path
    let that = this
    this.switchPath(path)
    this.$bus.$on('setRoute', (data) => {
      that.switchPath(data)
    })
    window.addEventListener('scroll', this.handleScroll);
  },

  // 销毁之前
  beforeDestroy() {
    this.$bus.off('setRoute')
  },

  methods: {

    switchPath(path) {
      switch (path) {
        case '/home':
          this.chooseIndex = 0
          break;
        case '/service':
          this.chooseIndex = 1
          break;
        case '/datacenter':
          this.chooseIndex = 2
          break;
        case '/about':
          this.chooseIndex = 3
          break;
        default:
          break;
      }
    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.bgHeight = scrollTop
    },

    newAddBtn() {
      let me = this;
      this.$nextTick(function () {
        me.inputStyWidth = me.$refs.companyStyle.$el.clientWidth + 'px';
      })
    },
    // goHome() {
    //   this.$router.push("/home");
    //   this.chooseIndex = 0
    // },
    jumppage(val) {
      if (val < 4) {
        this.chooseIndex = val
      }

      switch (val) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/service");
          break;
        case 2:
          this.$router.push("/datacenter");
          break;
        case 3:
          this.$router.push("/about");
          break;
        // case 4:
        //   this.handleLanguage();
        //   break;

        default:
          break;
      }
    },

    // 中英文切换
    handleLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh'
    },

  },
};
</script>

<style lang="less" scoped>
.contain {
  background: transparent;
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding-bottom: 10px;


  .main {
    display: flex;
    height: 80px;
    width: 1400px;
    margin: 0 auto;
    justify-content: space-between;
    background: transparent;

    .logoBox {
      height: 80px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 18px;
    }

    .title {
      width: 424px;

      ul {
        display: flex;
        align-items: center;
        line-height: 80px;
        justify-content: space-around;

        li {
          cursor: pointer;
          color: #fff;
          overflow: hidden;
          position: relative;
          font-size: 18px;



          .borderBottom {
            width: 100%;
            height: 6px;
            background: linear-gradient(to right, #70C6ED, #5AFCDF);
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        li:hover {
          color: #00acf6;
          contain: '12312';
        }
      }
    }
  }
}

.addBgColor {
  background: #fff;
  color: #000;
}

.listAddColor {
  color: #333 !important;
}

.active {
  // color: #3bc7fc;
  background: linear-gradient(to right, #70C6ED, #5AFCDF);
  -webkit-background-clip: text;
  /*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent;
  /*给文字设置成透明*/
}
</style>