<template>
  <div class="box">
    <div class="bannerBox">
      <div class="aboutAdd">产业服务</div>
      <el-image class="img" :src="banner"></el-image>
    </div>

    <div class="mainBox">

      <div class="innerNo1">
        <div class="no1Title"> 数据中心规划咨询 </div>
        <div class="no1ItemBox">
          <div class="no1Item" v-for="item in consultAry" :key="item.id">
            <el-image class="img" :src="deviceWidth*1 > 1500 ? item.img : item.imgSmall"></el-image>
            <div class="no1Name">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div class="innerNo2">
        <div class="no2TopBox">
          <div class="no2Content">
            <div class="no2Title"> 数据中心运维 </div>
            <div class="no2TxtInner">
              <div class="no2Txt"> 瑞丰瀚维以专业的技术团队和完善的服务体系为企业客户提供数据中心建设、资源整合和长期运维服务，以超预期的IT基础设施服务支持客户关键业务。 </div>
              <!-- <div> 瑞丰瀚维以专业的技术团队和完善的服务体系为企业客户提供数据中心建设、资源整合和长期运维服务，以超预期的IT基础设施服务支持客户关键业务。 </div> -->
            </div>
          </div>
          <div class="no2ImgBox">
            <div class="no2Img1">
              <el-image class="img" :src="deviceWidth*1 > 1500 ? planObj.planImg1:planObj.planImg1_1"></el-image>
            </div>
            <div class="no2Img2">
              <el-image class="img" :src="deviceWidth*1 > 1500 ? planObj.planImg2 : planObj.planImg2_2"></el-image>
            </div>
          </div>
        </div>
        <div class="no2Down">
          <el-image class="img" :src="deviceWidth*1 > 1500 ? planObj.planBg : planObj.planBg1"></el-image>
        </div>
      </div>

      <div class="innerNo3">
        <div class="no3Title"> 数字信息服务 </div>
        <div class="no3ImgBox">
          <div class="no3itemImg" v-for="item in serveAry" :key="item.id">
            <div class="no3Txt"> {{ item.name }} </div>
            <el-image class="img" :src="deviceWidth*1 > 1500 ? item.img : item.imgSmall"></el-image>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Service',
  data() {
    return {
      deviceWidth: window.innerWidth,
      banner: require("./images/banner.png"),
      consultAry: [
        {
          name: '选址',
          img: require("./images/address.png"),
          imgSmall: require("./images/address_1.png"),
          id: 1
        },
        {
          name: '方案设计',
          img: require("./images/design.png"),
          imgSmall: require("./images/design_1.png"),
          id: 2
        },
        {
          name: '建设',
          img: require("./images/build.png"),
          imgSmall: require("./images/build_1.png"),
          id: 3
        }
      ],
      planObj: {
        planImg1: require("./images/plan1.png"),
        planImg1_1: require("./images/plan1_1.png"),
        planImg2: require("./images/plan2.png"),
        planImg2_2: require("./images/plan2_2.png"),
        planBg: require("./images/planBg.png"),
        planBg1: require("./images/planBg_1.png"),
      },
      serveAry: [
        { img: require("./images/serveImg1.png"), imgSmall: require("./images/serveImg1_1.png"), name: '数据基础设施', id: 1 },
        { img: require("./images/serveImg2.png"), imgSmall: require("./images/serveImg2_2.png"), name: '数据服务平台', id: 2 },
        { img: require("./images/serveImg3.png"), imgSmall: require("./images/serveImg3_3.png"), name: '数据应用', id: 3 },
      ]
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      console.log('deviceWidth', this.deviceWidth)
      this.deviceWidth = window.innerWidth;
      this.$forceUpdate(); // 强制重新渲染以响应屏幕宽度变化
    }
  }
}
</script>

<style lang='less' scoped>
.box {
  width: 100%;

  .bannerBox {
    width: 100%;
    height: 100%;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
      font-size: 64px;
      color: #fff;
      position: absolute;
      z-index: 10;
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .mainBox {
    width: 1400px;
    margin: 0 auto;
    font-size: 18px;
    padding: 30px 0;

    .innerNo1 {
      text-align: center;

      .no1Title {
        font-size: 24px;
        font-weight: bold;
      }

      .no1ItemBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .no1Item {
          text-align: center;
          margin-right: 20px;
          margin-top: 30px;

          .no1Name {
            padding: 20px 0;
          }
        }
      }
    }

    .innerNo2 {
      padding-top: 100px;

      .no2TopBox,
      .no2ImgBox {
        display: flex;
      }

      .no2TopBox {
        justify-content: space-between;

        .no2Content {
          margin-top: 200px;
          flex: 1;

          .no2Title {
            font-size: 24px;
            font-weight: bold;
          }

          .no2TxtInner {
            margin-left: 40px;
            font-size: 18px;

            .no2Txt {
              margin: 60px 0 40px;
              width: 400px;
            }
          }
        }

        .no2ImgBox {
          // flex: 1;
          margin-right: 60px;
          position: relative;

          .no2Img1 {
            position: absolute;
            left: -280px;
            bottom: -150px;
            z-index: 10;
          }

          .no2Img2 {
            margin-left: auto;
          }
        }
      }
    }

    .innerNo3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 100px 0;

      .no3Title {
        font-size: 24px;
        font-weight: bold;
      }

      .no3ImgBox {
        display: flex;
        align-items: center;

        .no3itemImg {
          margin-left: 20px;

          // display: flex;
          // align-items: center;
          // justify-content: center;

          position: relative;
          display: inline-block;

          .no3Txt {
            font-size: 18px;
            position: absolute;
            top: 50%;
            // left: 50%;
            width: 60%;
            margin: 0 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 99;
            color: #fff;
            /* 如果需要文本居中 */
          }
        }
      }
    }
  }
}
</style>