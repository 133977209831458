<template>
    <div class="contain">
        <div class="imgBox" id="数据中心">
            <div class="aboutAdd">数据中心</div>
            <el-image class="img" :src="img"></el-image>
        </div>

        <div class="seekBox" :style="{ backgroundImage: `url(${seekBg})` }">
            <div class="seekInner">
                <div class="seekTitle"> 数据中心规划咨询 </div>
                <div class="seekMain">
                    <div class="seekItem">
                        <div class="seekLeft addMargin">
                            <div class="itemBox">
                                <div class="fa_A">
                                    <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek1 : seek1_1"></el-image>
                                    <div class="itemTxt"> GB50174A级机房 </div>
                                </div>
                                <div class="fa_B"> 增强级GB50174-2017 A级 </div>
                            </div>
                            <div class="itemBox">
                                <div class="fa_A">
                                    <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek2 : seek2_1"></el-image>
                                    <div class="itemTxt"> 高稳定的环境 </div>
                                </div>
                                <div class="fa_B"> 高稳定，高安全，可独立空间围蔽部署 </div>
                            </div>
                        </div>
                        <div class="seekLeft">
                            <div class="itemBox">
                                <div class="fa_A">
                                    <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek3 : seek3_1"></el-image>
                                    <div class="itemTxt"> 模块化灵活性部署 </div>
                                </div>
                                <div class="fa_B"> 匹配业务需求，快速部署交付 </div>
                            </div>
                            <div class="itemBox">
                                <div class="fa_A">
                                    <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek4 : seek4_1"></el-image>
                                    <div class="itemTxt"> 弹性扩容 </div>
                                </div>
                                <div class="fa_B"> 根据用户不同需求提供定制改造 </div>
                            </div>
                            <div class="itemBox">
                                <div class="fa_A">
                                    <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek5 : seek5_1"></el-image>
                                    <div class="itemTxt"> 云服务迁移 </div>
                                </div>
                                <div class="fa_B"> 私有云、托管云、公有云和混合云的多云环境 </div>
                            </div>
                        </div>
                    </div>
                    <div class="seekItem addFlex">
                        <el-image class="img" :src="deviceWidth * 1 > 1500 ? seek6 : seek6_1"></el-image>
                    </div>
                </div>
            </div>
        </div>

        <div class="serviceBox" :style="{ backgroundImage: `url(${serviceBg})` }">
            <div class="serviceInner">
                <div class="serviceTitle"> 数据中心维护 </div>
                <div class="serviceMain">
                    <div class="serviceItem">
                        <el-image class="img" :src="deviceWidth * 1 > 1500 ? service1 : service1_1"></el-image>
                    </div>

                    <div class="serviceItem serviceRight">
                        <div class="serviceItemBox serviceAddMargin">
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service2 : service2_1"></el-image>
                                <div class="itemTxt"> 运营商维护 </div>
                            </div>
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service3 : service3_1"></el-image>
                                <div class="itemTxt"> 7*24*365人员值守 </div>
                            </div>
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service4 : service4_1"></el-image>
                                <div class="itemTxt"> 灾难预警 </div>
                            </div>
                        </div>
                        <div class="serviceItemBox">
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service5 : service5_1"></el-image>
                                <div class="itemTxt"> 极速响应 </div>
                            </div>
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service6 : service6_1"></el-image>
                                <div class="itemTxt"> AI智能管理系统 </div>
                            </div>
                            <div class="itemBox">
                                <el-image class="img" :src="deviceWidth * 1 > 1500 ? service7 : service7_1"></el-image>
                                <div class="itemTxt"> 人性化综合配套服务 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Datacenter",
    data() {
        return {
            deviceWidth: window.innerWidth,

            img: require("./images/banner.png"),

            seek1: require("./images/seek1.png"),
            seek2: require("./images/seek2.png"),
            seek3: require("./images/seek3.png"),
            seek4: require("./images/seek4.png"),
            seek5: require("./images/seek5.png"),
            seek6: require("./images/seek6.png"),
            seek1_1: require("./images/seek1_1.png"),
            seek2_1: require("./images/seek2_1.png"),
            seek3_1: require("./images/seek3_1.png"),
            seek4_1: require("./images/seek4_1.png"),
            seek5_1: require("./images/seek5_1.png"),
            seek6_1: require("./images/seek6_1.png"),
            seekBg: require("./images/seekBg.png"),

            service1: require("./images/service1.png"),
            service1_1: require("./images/service1_1.png"),
            service2: require("./images/service2.png"),
            service3: require("./images/service3.png"),
            service4: require("./images/service4.png"),
            service5: require("./images/service5.png"),
            service6: require("./images/service6.png"),
            service7: require("./images/service7.png"),
            service2_1: require("./images/service2_1.png"),
            service3_1: require("./images/service3_1.png"),
            service4_1: require("./images/service4_1.png"),
            service5_1: require("./images/service5_1.png"),
            service6_1: require("./images/service6_1.png"),
            service7_1: require("./images/service7_1.png"),
            serviceBg: require("./images/serviceBg.png")

        }
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            console.log('deviceWidth', this.deviceWidth)
            this.deviceWidth = window.innerWidth;
            this.$forceUpdate(); // 强制重新渲染以响应屏幕宽度变化
        }
    }
};
</script>

<style lang='less' scoped>
.imgBox {
    width: 100%;
    // min-width: 1200px;
    // height: 610px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
        font-size: 64px;
        color: #fff;
        position: absolute;
        z-index: 10;
    }

    .title {
        color: #fff;
        z-index: 10;
        position: absolute;
        font-size: 64px;
    }

    .img {
        width: 100%;
        height: 100%;
    }
}

.seekBox {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1100px;
    width: 100%;
    display: flex;
    align-items: center;

    .seekInner {
        width: 1400px;
        margin: 0 auto;

        .seekTitle {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 100px;
        }

        .seekMain {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .seekItem {
                flex: 1;

                .seekLeft {
                    display: flex;
                    justify-content: space-around;

                    .itemBox {
                        text-align: center;
                        position: relative;
                        width: 190px;
                        height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .fa_A,
                        .fa_B {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 190px;
                            height: 120px;
                            
                            // padding: 20px;
                            box-sizing: border-box;
                            transition: transform ease 1s; //设置过渡动画，翻滚过渡
                            backface-visibility: hidden; //该属性与transform： rotate相关，背面朝上的元素隐藏
                        }

                        .fa_A {
                            // background: #141B3F;
                            color: #fff;
                            font-size: 22px;
                            transform: perspective(180px) rotateY(0); //设置正面的旋转角度及透视
                        }

                        .fa_B {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // background: #fff;
                            color: #00082F;
                            font-size: 16px;
                            transform: perspective(180px) rotateY(-180deg); //设置背面的旋转角度及透视
                        }

                        &:hover {
                            .fa_A {
                                transform: perspective(180px) rotateY(-180deg); //设置鼠标移入后的状态
                            }

                            .fa_B {
                                transform: perspective(180px) rotateY(0deg); //设置鼠标移入后的状态
                            }
                        }

                        .itemTxt {
                            font-size: 16px;
                            color: #7B7583;
                            padding-top: 10px;
                        }
                    }
                }

                .addMargin {
                    margin-bottom: 80px;
                }
            }

            .addFlex {
                text-align: center;
            }
        }
    }
}

.serviceBox {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1100px;
    width: 100%;
    display: flex;
    align-items: center;

    .serviceInner {
        width: 1400px;
        margin: 0 auto;

        .serviceTitle {
            font-size: 24px;
            font-weight: bold;
        }

        .serviceMain {
            padding: 80px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .serviceItem {
                flex: 1;
                text-align: center;

                .serviceItemBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .itemBox {
                        text-align: center;
                        width: 200px;

                        .itemTxt {
                            font-size: 16px;
                            color: #7B7583;
                            padding-top: 10px;
                        }
                    }

                }

                .serviceAddMargin {
                    margin-bottom: 40px;
                }
            }

            // .serviceRight {
            //     display: flex;
            //     align-items: center;
            // }
        }
    }
}
</style>