<template>
  <div class="footer">
    <div class="main">
      <div class="title"> 联系我们 </div>
      <div class="inforBox">
        <div class="inforTop">
          <div class="item"> 地址：北京市经济技术开发区地盛北街1号院31-2 </div>
          <div class="item"> 电话：010-87227768 </div>
          <div> 邮箱：hanwei@rfhw.cn </div>
        </div>
        <!-- <div> icon </div> -->
      </div>
      <div class="filingsBox">
        <a href="https://beian.miit.gov.cn">COPYRIGHT © 2022 北京瑞丰瀚维科技发展有限公司. All Rights Reserved 京ICP备2022018034号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
export default {
  name: "Footers",
  data() {
    return {

      screenWidth: document.documentElement.clientWidth,//屏幕宽度
      centerDialogVisible: false,
    }
  },

  // <!--在watch中监听实时宽高-->
  watch: {
    screenWidth: function (val) {
      console.log('width', val)
      if (val < 1024) {
        this.centerDialogVisible = true
      } else {
        this.centerDialogVisible = false
      }
    }
  },

  mounted() {
    let that = this
    window.addEventListener('resize', function () {
      that.screenWidth = document.body.offsetWidth;
    })
  },

  methods: {


    // 回到顶部
    topClick() {
      VueScrollTo.scrollTo({ offset: 0 });
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  padding: 60px 0 20px;
  background-color: #f5f5f5;

  .main {
    width: 1400px;
    margin: 0 auto;

    .title {
      font-size: 32px;
      background: linear-gradient(to bottom, #70C6ED, #5AFCDF);
      -webkit-background-clip: text;
      /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent;
      /*给文字设置成透明*/
      margin-bottom: 20px;
    }

    .inforBox{
      margin-bottom: 30px;

      .inforTop{
        font-size: 17px;
        color: #7B7583;

        .item{
          margin-bottom: 5px;
        }
      }
    }

    .filingsBox{
      font-size: 15px;
      color: #7B7583;
      padding: 20px 0;
    }
  }
}
</style>