
<template>
  <div class="contain">

    <div class="aboutBox" v-show="!recruitShow">
      <div class="imgBox">
        <div class="aboutAdd">关于我们</div>
        <el-image class="img" :src="banner"></el-image>
      </div>

      <div class="arrBox">
        <div class="arrMain">
          <div class="arrTxtBox">
            <div class="txtBox"> AI、元宇宙、区块链、云计算为未来创造了无限可能，高端化、智能化、绿色化新型产业快速增长。
              北京瑞丰瀚维科技发展有限公司，愿与大家一起共同探索科学技术的前沿，为全社会创造价值，携手共建智能未来。</div>
          </div>
          <div class="arrItem">
            <div class="arrImgBox" v-for="item in arr" :key="item.id">
              <el-image class="img addLeft" :src="deviceWidth * 1 > 1500 ? item.img : item.imgSmall"></el-image>
            </div>
          </div>
        </div>
      </div>

      <div class="historyBox ">
        <div class="historyMain">
          <div class="historyTitle"> 发展历程 </div>

          <div class="historyInnerBox">
            <div class="historyItem">
              <div class="smoke"></div>
              <div class="historyTxtBox">
                <div> 奠基 2013 </div>
                <div> 建成并投产中移铁通IDC-T3中心 </div>
              </div>
            </div>

            <div class="historyItem">
              <div class="historyTxtBox">
                <div> 聚力 2017 </div>
                <div> 面向高端客户提供综合大数据服务 </div>
              </div>
              <div class="smoke smoke2"></div>
            </div>

            <div class="historyItem">
              <div class="smoke smoke3"></div>
              <div class="historyTxtBox">
                <div> 未来 </div>
                <div> 共建数字智能时代 </div>
              </div>
            </div>
          </div>
          <div class="timeImg" :style="{ backgroundImage: `url(${historyImg})` }"></div>

        </div>
      </div>

      <div class="brief" id="联系我们">
        <div class="briefTop">
          <div class="briefTitle"> 联系我们 </div>
        </div>
        <div class="mapBox">

          <div class="mapMain">
            <div class="mapInner">
              <div class="mapItemTxt"> 北京铁通IDC-T3数据中心 </div>
              <div class="mapItemTxt"> 技术服务中心 </div>
              <div class="mapItemTxt"> 北京经济技术开发区兴盛街 15 号 </div>
              <div class="mapItemTxt"> 010-87227768 </div>
              <div class="mapItemTxt"> hanwei@rfhw.cn </div>
            </div>
          </div>

          <div class="mapMapBox" id="container" style="width:100%;height:100%;position:relative">
            <i class="el-icon-s-help" @click="locationClick"
              style="fontSize:30px;color:#113f7d;zIndex:999;position: absolute;top: 50px;right: 30px;cursor: pointer;"></i>
          </div>

        </div>
      </div>

      <div class="addBox">
        <div class="addMain">
          <div class="addTxt"> 加入我们 </div>
          <div class="addMask"></div>
          <el-image class="img" :src="add"></el-image>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

var VueScrollTo = require("vue-scrollto");
export default {
  name: "About",
  data() {
    return {
      banner: require("./images/banner.png"),
      historyImg: require("./images/historyImg.png"),
      add: require("./images/add.png"),
      arr: [
        // {
        //   isShow: false,
        //   id: 1,
        //   name: 'AI、元宇宙、区块链、云计算为未来创造了无限可能，高端化、智能化、绿色化新型产业快速增长。北京瑞丰瀚维科技发展有限公司，秉承着以人为本的企业理念，承担民营企业社会责任.....',
        //   img: ''
        // },
        {
          isShow: true,
          id: 2,
          name: '',
          img: require('./images/1.png'),
          imgSmall: require('./images/1_1.png')
        },
        {
          isShow: true,
          id: 3,
          name: '',
          img: require('./images/2.png'),
          imgSmall: require('./images/2_2.png'),
        },
        {
          isShow: true,
          id: 4,
          name: '',
          img: require('./images/3.png'),
          imgSmall: require('./images/3_3.png'),
        },
      ],
      recruitShow: false,
      active: 1,
      longitude: '116.522315', // 经度
      latitude: '39.795178', // 纬度

      deviceWidth: window.innerWidth,
    };
  },
  mounted() {
    this.init(39.795178, 116.522315);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      console.log('deviceWidth', this.deviceWidth)
      this.deviceWidth = window.innerWidth;
      this.$forceUpdate(); // 强制重新渲染以响应屏幕宽度变化
    },

    // 初始定位
    init(val, val2) {
      // var center = new qq.maps.LatLng(39.79488, 116.52242); // 39.79517896441373 116.52231574058533
      var center = new qq.maps.LatLng(val, val2); // 39.79517896441373 116.52231574058533
      var map = new qq.maps.Map(document.getElementById('container'), {
        center: center,
        zoom: 14
      });
      var anchor = new qq.maps.Point(6, 6), size = new qq.maps.Size(24, 24), origin = new qq.maps.Point(0, 0), icon = new qq.maps.MarkerImage('https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/center.gif', size, origin, anchor);
      var marker = new qq.maps.Marker({
        icon: icon,
        map: map,
        position: map.getCenter()
      });
    },
    // 回到定位点
    locationClick() {
      this.tabClick(this.latitude, this.longitude, this.active);
    },
    tabClick(val, val2, val3) {
      this.init(val, val2);
      this.latitude = val;
      this.longitude = val2;
      this.active = val3;
    },
    backClick() {
      this.recruitShow = false;
    }
  },
};
</script>

<style lang='less' scoped>
.aboutBox {
  .imgBox {
    width: 100%;
    // min-width: 1200px;
    height: 610px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
      font-size: 64px;
      color: #fff;
      position: absolute;
      z-index: 10;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .arrBox {
    padding: 100px 0;

    .arrMain {
      // width: 1400px;
      // margin: 0 auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      overflow: hidden;

      .arrTxtBox {
        // flex: 1;


        .txtBox {
          font-size: 16px;
          color: #7B7583;
          width: 300px;
          // margin: 0 auto
          margin-left: 200px;
        }
      }

      .arrItem {
        // flex: 1;
        display: flex;
        align-items: center;
        margin-left: auto;
        overflow: hidden;
        margin-right: -260px;


        .arrImgBox {

          .addLeft {
            margin-left: 30px;
          }
        }
      }
    }
  }

  .historyBox {

    // min-height: 370px;
    padding: 100px 0;


    .historyMain {
      position: relative;

      .historyTitle {
        padding-bottom: 200px;
        font-size: 24px;
        text-align: center;
      }

      .timeImg {
        width: 100%;
        min-height: 370px;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .historyInnerBox {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;

        position: absolute;
        margin-top: -100px;
        margin-left: 14%;

        .historyItem {

          .smoke {
            width: 4px;
            height: 300px;
            margin: 0 auto;
            background-image: linear-gradient(to top, #fff, #96CDB9);
          }

          .smoke2 {
            height: 400px;
            background-image: linear-gradient(to top, #96CDB9, #fff);
            margin-bottom: 300px;
          }

          .smoke3 {
            height: 500px;
          }

          .historyTxtBox {
            margin: 20px 0;
            font-size: 16px;
            color: #7B7583;
          }
        }
      }
    }
  }


  .brief {
    width: 1400px;
    min-height: 300px;
    margin: 0 auto;
    padding: 200px 0 100px;
    // display: flex;
    // align-items: center;

    .briefTop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 60px;


      .briefTitle {
        font-size: 24px;
        color: #001c41;
        text-align: center;
      }
    }

    .mapBox {
      // position: relative;
      display: flex;
      align-items: center;
      width: 1400px;
      height: 500px;
      overflow: hidden;

      .mapMain {
        // position: absolute;
        // left: 84px;
        // width: 426px;
        flex: 1;
        height: 500px;
        z-index: 10;
        // background: #113f7d;
        display: flex;
        align-items: center;
        justify-content: center;

        .mapInner {
          color: #333;
          font-size: 16px;
          text-align: center;

          .mapItemTxt {
            padding: 10px 0;
          }
        }
      }

      .mapMapBox {
        flex: 1;
        min-height: 500px;
      }
    }
  }

  .addBox {
    // margin-bottom: 100px;

    .addMain {
      position: relative;
      width: 100%;

      .img {
        width: 100%;
        height: 100%
      }

      .addTxt {
        font-size: 30px;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 48%;
        left: 46%;
        z-index: 99;
      }

      .addMask {
        width: 100%;
        min-height: 275px;
        background: #fff;
        position: absolute;
        top: 0;
        height: 0;
        z-index: 9;
        opacity: 0.3;
      }
    }
  }

}
</style>